// homepage
import shopping from '../assets/images/svg/shopping.png';
import search from '../assets/images/svg/search.png';
import bus from '../assets/images/svg/bus.png';
import telephone from '../assets/images/svg/telephone.png';
import newspaper from '../assets/images/svg/newspaper.png';
import home from '../assets/images/svg/home.png';

// agencypage
import clients from '../assets/images/svg/clients.png';
import control from '../assets/images/svg/control.png';
import lock from '../assets/images/svg/lock.png';
import payment from '../assets/images/svg/payment.png';
import phone from '../assets/images/svg/phone.png';
import problems from '../assets/images/svg/problems.png';

export const homeSections = [
    {
        icon: shopping,
        heading: 'Sifariş rahatlığı',
        text: 'WTrips tətbiqində tur sifariş vermək üçün harasa getməyə və ya zəng etməyə ehtiyac yoxdur',
        id: 1
    },
    {
        icon: telephone,
        heading: 'Dəstək',
        text: 'İstifadəçilər tətbiq daxilindən və ya əlaqə vasitələrindən bizə öz təklif, problem və iradlarını bildirə və dəstək ala bilərlər',
        id: 2
    },
    {
        icon: home,
        heading: 'Otellər',
        text: 'Azərbaycanın ən yaxşı otel və rekreasiya müəssələrini çox rahat bronlaşdırma imkanı',
        id: 3
    },
    {
        icon: newspaper,
        heading: 'Xəbərlər',
        text: 'Hər gün yenilənən turizm xəbərləri ilə dünya və Azərbaycanda baş verən turizm yeniliklərindən ən tez siz xəbərdar olun',
        id: 4
    },
    {
        icon: search,
        heading: 'Axtarış filteri',
        text: 'Kriteriyalarınızı daxil edərək istəyinizə və büdcənizə uyğun turları tapma imkanı',
        id: 5
    },
    {
        icon: bus,
        heading: 'Yer seçimi',
        text: 'Hər bir tur üçün öncədən yer seçimi etmə imkanı',
        id: 6
    },
]

export const agencySections = [
    {
        icon: clients,
        heading: 'Yeni müştərilər',
        text: 'WTrips tətbiqindən istifadə edən hər kəs sizin potensial müştəriləriniz olacaq.',
        id: 1
    },
    {
        icon: control,
        heading: 'İdarəetmə paneli',
        text: 'Tur və sifarişlərinizi idarə etmək yeni moderator panelində daha asan olacaq.',
        id: 2
    },
    {
        icon: lock,
        heading: 'Etibarlılıq',
        text: 'WTrips şirkətlərin maliyyə nəzarətini asanlaşdırır, sifarişlərdə şirkətdaxili şəffaflıq yaradır.',
        id: 3
    },
    {
        icon: problems,
        heading: 'Problemlərin həlli',
        text: 'Elektron bilet, yerlərin və nömrə tipinin əvvəlcədən seçilə bilməsi müştərilərlə bir çox problemi həll edəcək.',
        id: 4
    },
    {
        icon: phone,
        heading: 'Dəstək',
        text: 'WTrips idarəetmə panelində yaranan hər-hansı sual və problemlə bağlı, komandamız sizə kömək edəcək',
        id: 5
    },
    {
        icon: payment,
        heading: 'Nəğdsiz ödənişlər',
        text: 'Tətbiq daxilindən edilə bilən online ödənişlər sifariş prosesini daha da sürətləndirməyə kömək edir.',
        id: 6
    },
]