import React from 'react'
import instagram from '../../assets/images/svg/icons8-instagram-50.png'
import facebook from '../../assets/images/svg/icons8-facebook-f-30.png'
import whatsapp from '../../assets/images/svg/icons8-whatsapp-50.png'
import mail from '../../assets/images/svg/icons8-mail-50.png'

const Contact = ({refProp}) => {
    return (
        <div ref={refProp} id='contact'>
            <h2>Əlaqə</h2>
            <div className='contact'>
                <ul className='left-side'>
                    <li><a href="tel:+994-55-210-59-07">Telefon: +994 55 210 59 07</a></li>
                    {/* <li><a href="mailto: office@weekendtrips.az">E-mail: office@weekendtrips.az</a></li> */}
                    <li>Ünvan: Bakı şəhəri, M.H.Naxçıvani küçəsi 22</li>
                </ul>
                <div className='right-side'>
                    <div>
                        <span className='facebook'><img src={facebook} alt="facebook" />Facebook</span>
                        <span className='whatsapp'><img src={whatsapp} alt="whatsapp" /><a href="tel:+994-55-210-59-07">WhatsApp</a></span>
                    </div>
                    <div>
                        <span onClick={() => window.open('https://www.instagram.com/wtrips.az/', '_blank')} className='instagram'><img src={instagram} alt="instagram" />Instagram</span>
                        <span className='mail'><img src={mail} alt="mail" />Mail</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact