import React from 'react'
import closeIcon from '../../assets/images/svg/close.png'
import { errorAlert, successAlert } from '../../assets/showAlerts'
import { useFormik } from "formik";
import * as yup from "yup";

const Form = ({ refProp }) => {

    const filePickerRef = React.useRef(null);
    const [selectedFile, setSelectedFile] = React.useState(null)
    const [sendFile, setSendFile] = React.useState(null)
    const [taxID, setTaxID] = React.useState('')
    const [cityPhone, setCityPhone] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const schema = yup.object().shape({
        email: yup
            .string()
            .email("E-poçt düzgün deyil")
            .required("Zəhmət olmasa e-poçt daxil edin"),
        name: yup.string().required("Zəhmət olmasa şirkətin adını qeyd edin"),
        phone: yup
            .number()
            .integer()
            .required("Zəhmət olmasa əlaqə nömrənizi daxil edin"),
    });


    const addImagetoPost = (e) => {
        setSendFile(e.target.files[0])
        const reader = new FileReader()
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }

        reader.onload = (readerEvent) => {
            setSelectedFile(readerEvent.target.result)
        }
    }

    // function submitHandler(e) {
    //     e.preventDefault()
    //     setLoading(true)
    //     var formData = new FormData();
    //     formData.append('file', sendFile);
    //     formData.append('name', name);
    //     formData.append('email', email);
    //     formData.append('phone', phone);
    //     formData.append('taxID', taxID);
    //     formData.append('cityPhone', cityPhone);

    //     fetch("https://weekendtrips.az:8443/api/agencies/auth/register", {
    //         method: "POST",
    //         body: formData
    //     })
    //         .then((response) => response.json())
    //         .then(responseJson => {
    //             const { isRegistered, message } = responseJson.user;
    //             setLoading(false)
    //             if (isRegistered) {
    //                 successAlert(message, () => window.location.replace('/'))
    //             } else {
    //                 errorAlert(message)
    //             }
    //         }).catch((err) => {
    //             setLoading(false)
    //             errorAlert('Bilinməyən xəta baş verdi. Qeydiyyat formunu düzgün oldurduğunuzdan və internet bağlantınızın mövcudluğundan əmin olun.')
    //         })
    // }

    function onSubmit (values) {

        const {name, email, phone} = values

        console.log('name ', values.name);
        console.log('email ', values.email);
        console.log('phone ', values.phone);
        console.log('taxID ', taxID);
        console.log('cityPhone ', cityPhone);

        fetch("https://weekendtrips.az:8443/api/agencies/auth/register", {
            method: "POST",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name,
                email,
                tax_id: taxID,
                city_phone: cityPhone,
                phone
            })
        })
            .then((response) => response.json())
            .then(responseJson => {
                const { isRegistered, message } = responseJson.user;
                setLoading(false)
                if (isRegistered) {
                    successAlert(message, () => window.location.replace('/'))
                } else {
                    errorAlert(message)
                }
            }).catch((err) => {
                setLoading(false)
                errorAlert(err)
            })
    };

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
    } = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: null
        },
        validationSchema: schema,
    });

    return (
        <div ref={refProp} id='form'>
            <div className='header'>
                <h2>Korporativ əlaqələr üçün ilkin qeydiyyat</h2>
                <p>Əgər siz də WTrips platformasında öz turizm məhsullarınızın satışını təmin etmək istəyirsinizsə, bunun üçün aşağıdakı formu düzgün məlumatlarla təsdiqləyərək ilkin qeydiyyatı həyata keçirə bilərsiniz. Bundan sonra əməkdaşlarımız sizinlə əlaqə saxlayacaq, müvafiq rəsmiləşdimə proseslərindən sonra siz də öz məhsullarınızı bu platformada təqdim edə biləcəksiniz.</p>
            </div>
            <div className='inputs'>
                <div className='box'>
                    <div>
                        <label htmlFor="name">Şirkət adı</label>
                        <input onBlur={handleBlur} className={errors.name && touched.name ? "input-error" : ""} value={values.name} onChange={handleChange} placeholder='Şirkət adı' type="text" id='name' name='name' />
                        {errors.name && touched.name && (
                            <p className="errorMessage">{errors.name}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="country_number">Şəhər nömrəsi</label>
                        <input value={cityPhone} onChange={(event) => setCityPhone(event.target.value)} placeholder='Şəhər nömrəsi' type="number" id='country_number' name='country_number' />
                    </div>
                    <div>
                        <label htmlFor="phone">WhatsApp nömrəsi</label>
                        <input onBlur={handleBlur} className={errors.phone && touched.phone ? "input-error" : ""} value={values.phone} onChange={handleChange} placeholder='WhatsApp nömrəsi' type="text" id='phone' name='phone' />
                        {errors.phone && touched.phone && (
                            <p className="errorMessage">{"Əlaqə nömrəsi düzgün deyil"}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input onBlur={handleBlur} className={errors.email && touched.email ? "input-error" : ""} value={values.email} onChange={handleChange} placeholder='Elektron poçt ünvanı' type="text" id='email' name='email' />
                        {errors.email && touched.email && (
                            <p className="errorMessage">{errors.email}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="voen">VÖEN</label>
                        <input value={taxID} onChange={(event) => setTaxID(event.target.value)} placeholder='VÖEN' type="text" id='voen' name='voen' />
                    </div>                    
                    <button onClick={() => onSubmit(values)} disabled={isSubmitting} type='submit' className='end-button'>Tamamla</button>
                </div>
            </div>
        </div>
    )
}

export default Form