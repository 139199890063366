import React from 'react'

const About = ({refProp}) => {
    return (
        <div ref={refProp} id='about' className='container'>
            <div className='heading'>
                <p className='header'>WTrips haqqında</p>
                <h2 className='subheader'>Niyə var və necə işləyir?</h2>
            </div>
            <p >
                WTrips tətbiqinin yaranmasında məqsəd, Azərbaycan vətəndaşlarının və eyni zamanda respublikamızın qonaqlarının ölkəmizin gözəl guşələrinə təşkil etdiyimiz turlar, digər turizm məhsulları haqqında məlumatı daha qısa yolla çatdırmaq, online olaraq, turların sifarişi və ödənişinin tətbiqi ilə ən innovativ bir xidmət göstərməkdir. Tətbiq daxilində turlara baxmaqla, ürəyinizcə olan turu seçib onu səbətə əlavə edə bilərsiniz. Ödənişi etdikdən sonra isə avtobusda yer seçimi etmək mümkün olacaq. Tətbiq bütün prosesləri keçdikdən sonra sizin üçün QR bilet hazırlayır. Bu bilet əsasında sizin sifariş məlumatlarınız tur bələdçisinə verilmiş olur. Ödənişi həm online, həm terminal vasitəsi ilə və həmçinin ofisə gələrək həyata keçirmək mümkündür. Ödənişlə və ya başqa məsələlərlə yarana biləcək sualları nəzərə alaraq sizin üçün "Dəstək Mərkəzi" bölümünü hazırladıq. Bu bölmə vasitəsi ilə istədiyiniz mövzuda WTrips komandasının operatorlardan biri ilə söhbət pəncərəsi yarada və istədiyiniz sualları birbaşa soruşa bilərsiniz. Əgər sizə turizm xəbərləri maraqlıdırsa onda bizim Xəbərlər bölümü ən çox sizin üçün yararlı olacaq. Daim yenilənən xəbərlər ilə turizm iqtisadiyyatında hər yenilik ilk sizə çatacaq. Azərbaycanı WTrips-lə kəşf et!
            </p>
        </div>
    )
}

export default About