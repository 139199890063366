import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout/Layout';
import { refContext } from './Context'

// Pages
import Home from './pages/Home/Home';
import Register from './pages/Regiser/Register';

function App() {

  const refAbout = React.useRef(null)
  const refContact = React.useRef(null)
  const refHome = React.useRef(null)
  const refPrior = React.useRef(null)
  const refRegister = React.useRef(null)

  return (
    <refContext.Provider value={{refAbout, refContact, refHome, refPrior, refRegister}}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='register' element={<Register />} />
        </Route>
      </Routes>
    </refContext.Provider>

  );
}

export default App;
