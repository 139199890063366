import React from 'react'
import Navbar from './navbar/Navbar'
import google from '../../../assets/images/pictures/google-play-badge.png'
import appstore from '../../../assets/images/svg/appstore.svg'
import { refContext } from '../../../Context'
import { useLocation } from 'react-router-dom'

const Header = () => {
    const {refHome} = React.useContext(refContext)
    const location = useLocation()
    return (
        <div ref={refHome} id='header'>
            <Navbar />
            <div className='header'>
                <h1>{location.pathname === '/register' ? 'WTrips - Korporativ' : 'WTrips'}</h1>
                <h2>{location.pathname === '/register' ? 'Agentliklər üçün' : 'Azərbaycanı kəşf edin!'}</h2>
                <div>
                    <a href="https://play.google.com/store/apps/details?id=com.mm.ar.turizmaz" target='_blank'>
                        <img src={google} alt="google" />
                    </a>
                    <a href="https://apps.apple.com/az/app/wtrips/id6443588739" target='_blank'>
                        <img src={appstore} alt="appstore" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Header