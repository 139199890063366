import React from 'react'

// dbs
import { homeSections } from '../../db/SectionsDb'

// components
import Sections from '../../components/Sections/Sections'
import Images from '../../components/Images/Images'
import Slider from '../../components/Images/swiper/Slider'
import About from '../../components/About/About'
import Contact from '../../components/Contact/Contact'

import { refContext } from '../../Context'



const Home = () => {

  const { refAbout, refContact, refPrior } = React.useContext(refContext)

  React.useEffect(() => {
    const changePage = () => {
      window.scrollTo({ top: 0 });
    };
    changePage()
  }, [])

  return (
    <div id='home'>
      <Sections refProp={refPrior} header={'Üstünlüklərimiz'} subheader={'Niyə WTrips-i seçməlisiniz?'} array={homeSections} />
      <Images />
      <Slider />
      <About refProp={refAbout} />
      <Contact refProp={refContact} />
    </div>
  )
}

export default Home