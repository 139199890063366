import React from 'react'
import pic1 from '../../assets/images/pictures/pic1.png'
import pic2 from '../../assets/images/pictures/pic2.png'
import pic3 from '../../assets/images/pictures/pic3.png'
import pic4 from '../../assets/images/pictures/pic4.png'
import pic5 from '../../assets/images/pictures/pic5.png'
import pic6 from '../../assets/images/pictures/pic6.png'

const Images = () => {
  return (
    <div id='images'>
        <div>
            <img src={pic1} alt='pic'/>
        </div>
        <div>
            <img src={pic2} alt='pic'/>
        </div>
        <div>
            <img src={pic3} alt='pic'/>
        </div>
        <div>
            <img src={pic4} alt='pic'/>
        </div>
        <div>
            <img src={pic5} alt='pic'/>
        </div>
        <div>
            <img src={pic6} alt='pic'/>
        </div>
    </div>
  )
}

export default Images