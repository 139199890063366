import React from 'react'

const Section = ({icon, heading, text}) => {
  return (
    <div id='section'>
      <img src={icon} alt="icon" />
      <div className='text'>
      <h3>{heading}</h3>
      <p>{text}</p>
      </div>
    </div>
  )
}

export default Section