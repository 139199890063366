import React from 'react'
import pic1 from '../../../assets/images/pictures/pic1.png'
import pic2 from '../../../assets/images/pictures/pic2.png'
import pic3 from '../../../assets/images/pictures/pic3.png'
import pic4 from '../../../assets/images/pictures/pic4.png'
import pic5 from '../../../assets/images/pictures/pic5.png'
import pic6 from '../../../assets/images/pictures/pic6.png'

// swiper
// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Slider = () => {
  return (
    <Swiper
      id='slider'
      // install Swiper modules
      modules={[Navigation, Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide><img src={pic1} alt="pic" /></SwiperSlide>
      <SwiperSlide><img src={pic2} alt="pic" /></SwiperSlide>
      <SwiperSlide><img src={pic3} alt="pic" /></SwiperSlide>
      <SwiperSlide><img src={pic4} alt="pic" /></SwiperSlide>
      <SwiperSlide><img src={pic5} alt="pic" /></SwiperSlide>
      <SwiperSlide><img src={pic6} alt="pic" /></SwiperSlide>
    </Swiper>
  )
}

export default Slider