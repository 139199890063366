import React from 'react'
import Section from './section/Section'

const Sections = ({header, subheader, array, refProp}) => {
  return (
    <div ref={refProp} id='sections' className='container'>
        <div className='heading'>
        <p className='header'>{header}</p>
        <h2 className='subheader'>{subheader}</h2>
        </div>
        <div className='sections'>
            {array.map((obj, i) => <Section key={i} icon={obj.icon} heading={obj.heading} text={obj.text}/>)}
        </div>
    </div>
  )
}

export default Sections