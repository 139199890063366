import React from 'react'
import Logo from '../../../../assets/images/pictures/logo.png'
import Menu from '../../../../assets/images/svg/menu.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { refContext } from '../../../../Context'

const Navbar = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [sideMenuStatus, setSideMenuStatus] = React.useState(false)
    const [changePage, setChangePage] = React.useState(false)
    const { refAbout, refContact, refHome, refPrior, refRegister } = React.useContext(refContext)


    React.useEffect(() => {
        changePage ? navigate('/register') : navigate('/')
    }, [changePage, navigate])

    const handleClick = (ref) => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        setSideMenuStatus(false)
    }

    const handleSideMenu = () => {
        setSideMenuStatus(!sideMenuStatus)
    }

    const handlePageChange = () => {
        setChangePage(!changePage)
    }


    return (
        <div id='navbar'>
            <div className="container">
                <img src={Logo} alt='logo' />
                {location.pathname === '/register' ? (
                    <ul className={sideMenuStatus ? 'active' : ''}>
                        <li onClick={() => handleClick(refRegister)}>Qeydiyyat</li>
                        <li onClick={() => window.open('https://weekendtrips.az/moderators/', '_self')}>Daxil ol</li>
                    </ul>
                ) : (
                    <ul className={sideMenuStatus ? 'active' : ''}>
                        <li onClick={() => handleClick(refHome)}>Ana səhifə</li>
                        <li onClick={() => handleClick(refPrior)}>Üstünlüklərimiz</li>
                        <li onClick={() => handleClick(refAbout)}>Haqqımızda</li>
                        <li onClick={() => handleClick(refContact)}>Əlaqə</li>
                    </ul>
                )}
                <div className='nav-end'>
                    <button onClick={handlePageChange}>{changePage ? 'Ana səhifə' : 'Agentliklər üçün'}</button>
                    <img onClick={handleSideMenu} src={Menu} alt="menu" />
                </div>
            </div>
        </div>
    )
}

export default Navbar