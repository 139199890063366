import React from 'react'

// dbs
import { agencySections } from '../../db/SectionsDb'

// components
import Sections from '../../components/Sections/Sections'
import Form from '../../components/Form/Form'
import { refContext } from '../../Context'

const Register = () => {

  React.useEffect(() => {
    const changePage = () => {
      window.scrollTo({ top: 0 });
    };
    changePage()
  }, [])

  const { refRegister } = React.useContext(refContext)
  
  return (
    <div id='register'>
      <Sections header={'Korporativ üstünlüklər'} subheader={'WTrips-lə əməkdaşlıq sizə nə qazandıracaq?'} array={agencySections}/>
      <Form refProp={refRegister}/>
    </div>
  )
}

export default Register