const swal = require('sweetalert')

function customAlert(title, text, icon, cb = () => {}){
    swal({
        title,
        text,
        icon
    }).then(okPressed => cb())
}

function errorAlert(text, cb = () => {}){
    swal({
        title: 'Xəta',
        text,
        icon: 'error'
    }).then(okPressed => cb())
}

function successAlert(text, cb = () => {}){
    swal({
        title: 'Uğurlu!',
        text,
        icon: 'success'
    }).then(okPressed => cb())
}

function dataAlert(text){
    swal({
        title: 'Məlumat xətası',
        text,
        icon: 'error'
    })
}

function successfulTour(){
    swal({
        title: "Uğurlu!",
        text: "Yeni tur müvəffəqiyyətlə yerləşdirildi. Daxili moderatorların təsdiqindən sonra tur hərkəsə açıq şəkildə yayımlanacaq.",
        icon: "success",
    }).then( okPressed => window.location.reload())
}

function unSuccessfulTour(){
    swal({
        title: "Xəta!",
        text: "Yeni tur yerləşdirilərkən xəta baş verdi. Bu tip xətalar tez-tez baş verdiyi halda bizimlə əlaqə saxlayın.",
        icon: "error",
    }).then( okPressed => window.location.reload())
}

module.exports = {
    customAlert,
    dataAlert,
    successAlert,
    errorAlert,
    successfulTour,
    unSuccessfulTour,
}